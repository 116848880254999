<template>
  <section>
    <div class="offer-wrapper">
      <div class="container">
        <div class="offer">
          <div class="offer-content">
            <h2>
              <span>{{ offer_span[lang] }}</span> {{ offer_title[lang] }}
            </h2>
            <div class="description">
              <p>
                <span>{{ offer_description_span[lang] }}</span>

                {{ offer_description[lang] }}
              </p>
            </div>

            <ul>
              <li>
                <ic-sharp-gpp-good></ic-sharp-gpp-good> {{ offer_1[lang] }}
              </li>
              <li>
                <ic-sharp-gpp-good></ic-sharp-gpp-good> {{ offer_2[lang] }}
              </li>
              <li>
                <ic-sharp-gpp-good></ic-sharp-gpp-good> {{ offer_3[lang] }}
              </li>
              <li>
                <ic-sharp-gpp-good></ic-sharp-gpp-good> {{ offer_4[lang] }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import IcSharpGppGood from "@/components/svg/IcSharpGppGood.vue";
export default {
  components: {
    IcSharpGppGood,
  },
  props: {
    lang: {
      type: String,
    },
  },
  data() {
    return {
      offer_span: {
        en: "Profitable offers ",
        pl: "Opłacalne oferty",
      },
      offer_title: {
        en: "for our partners",
        pl: "dla naszych partnerów",
      },
      offer_description_span: {
        en: "Cooperation must be mutually beneficial.",
        pl: "Współpraca musi być korzystna dla obu stron.",
      },
      offer_description: {
        en: "It is on this principle that we have built our work. What our partners from Austria, the Czech Republic, and Canada were convinced of.",
        pl: "Na tej zasadzie zbudowaliśmy naszą pracę. O czym byli przekonani nasi partnerzy z Austrii, Czech i Kanady.",
      },
      offer_1: {
        en: "We offer quality and competitive products.",
        pl: "Oferujemy wysokiej jakości i konkurencyjne produkty.",
      },
      offer_2: {
        en: "We will ensure timely delivery due to the availability of finished products in stock.",
        pl: "Zapewnimy terminową dostawę ze względu na dostępność gotowych produktów na magazynie.",
      },
      offer_3: {
        en: "Individual approach and flexible system of discounts.",
        pl: "Indywidualne podejście i elastyczny system rabatów.",
      },
      offer_4: {
        en: "We develop models according to individual parameters.",
        pl: "Opracowujemy modele według indywidualnych parametrów.",
      },
    };
  },
  mounted() {
    this.animate();
  },
  methods: {
    animate() {
      gsap.to(".offer-content h2", {
        scrollTrigger: {
          trigger: ".offer-content h2",
        },
        duration: 1,
        opacity: 1,
      });
      gsap.to(".offer-content .description", {
        scrollTrigger: {
          trigger: ".offer-content .description",
        },
        duration: 1,
        opacity: 1,
      });
      gsap.to(".offer-content ul", {
        scrollTrigger: {
          trigger: ".offer-content ul",
        },
        duration: 1,
        opacity: 1,
      });
      gsap.to(".offer-content h2 span", {
        scrollTrigger: {
          trigger: ".offer-content h2 span",
          start: "center center",
        },
        duration: 1,
        color: "#75c9e5",
      });
    },
  },
};
</script>

<style scoped>
.offer-wrapper {
  padding: 50px 0;
  background: #fff;
}
.offer {
  position: relative;
}
.offer::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: var(--accent);
}
.offer-content {
  padding: 0 0 0 50px;
  font-weight: 700;
}
.offer-content h2 {
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
  opacity: 0;
}
.description {
  opacity: 0;
}
.offer-content p,
.offer-content li {
  color: var(--text);
}
ul {
  margin: 15px 0;
  opacity: 0;
}
ul li {
  list-style: none;
  padding: 15px;
  position: relative;
}
ul li svg {
  position: absolute;
  fill: var(--accent);
  top: calc(50% - 8px);
  left: -16px;
  width: 16px;
  height: 16px;
}
@media (max-width: 768px) {
  .offer-content {
    padding: 0 0 0 15px;
  }
  .offer::before {
    display: none;
  }
}
</style>
