<template>
  <footer>
    <div class="container">
      <div class="footer-content">
        <div class="footer-body">
          <div class="company">
            <div class="logo">Beregynya</div>
            <p></p>
            <p>
              {{ description[lang] }}
            </p>
          </div>

          <div class="menu">
            <div class="footer-title">{{ contacts[lang] }}</div>
            <ul>
              <li>
                <a target="blank" href="https://goo.gl/maps/Z2sj3G3gD1dhHuz58"
                  >Rodimceva 16, Chernihiv, Ukraine, 14000</a
                >
              </li>
              <li><a href="tel:380504443366">+38 050 444 33 66</a></li>
              <li>
                <a href="mailto:vmtz1945@gmail.com">vmtz1945@gmail.com</a>
              </li>
            </ul>
          </div>
          <div class="menu">
            <div class="footer-title">{{ menu[lang] }}</div>
            <ul>
              <li>
                <router-link to="/">{{ home[lang] }}</router-link>
              </li>
              <li>
                <router-link to="/catalog">Catalog</router-link>
              </li>
              <li>
                <router-link to="/contact">Contacts</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer">
          <div>
            {{ getYear() }} <span>© Beregynya, All rights reserved</span>
          </div>
          <div>
            <a href="https://core3w.com">Powered by core3w</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
    },
  },
  data() {
    return {
      description: {
        en: "Home slippers for children. We are always open to new proposals. If you have any questions about delivery, product quality, receiving a trial batch, or cooperation, please contact us.",
        pl: "Domowe kapcie dla dzieci. Jesteśmy zawsze otwarci na nowe propozycje. W przypadku pytań dotyczących dostawy, jakości produktu, otrzymania partii próbnej lub współpracy prosimy o kontakt.",
      },
      contacts: {
        en: "Contacts",
        pl: "Kontakty",
      },
      menu: {
        en: "Menu",
        pl: "Menu",
      },
      home: {
        en: "Home",
        pl: "Dom",
      },
      catalog: {
        en: "Catalog",
        pl: "Katalog",
      },
      contact: {
        en: "Contacts",
        pl: "Kontakty",
      },
    };
  },
  methods: {
    getYear() {
      let year = new Date();
      let fullYear = year.getFullYear();
      return fullYear;
    },
  },
};
</script>

<style scoped>
footer {
  border-top: 1px solid #cfd0d7;
  padding: 20px 0;
  background: rgb(12, 8, 29);
  color: #cfd0d7;
}
.footer-body {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 20px;
}
.company .logo {
  font-size: 24px;
  font-weight: 700;
}
.company p {
  font-size: 14px;
  color: #a9aab3;
  margin: 10px 0;
}
.footer {
  border-top: 1px solid #a9aab3;
  font-size: 14px;
  color: #a9aab3;
  margin: 20px 0 0 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer a {
  color: #a9aab3;
  text-decoration-line: none;
  transition: all 0.5s ease;
  cursor: pointer;
}
.footer a:hover {
  color: var(--active);
}
.menu {
  display: flex;
  flex-direction: column;
}
.menu ul li {
  list-style: none;
}
.menu ul li a {
  text-decoration-line: none;
  color: #a9aab3;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.menu ul li a.router-link-active {
  color: var(--accent);
}
.menu ul li a:hover {
  color: var(--accent);
}
.footer-title {
  padding: 10px 0;
  color: #cfd0d7;
}
@media (max-width: 768px) {
  .footer-body {
    display: flex;
    flex-direction: column;
  }
  .company,
  .menu {
    margin: 15px 0;
  }
}
@media (max-width: 476px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer div {
    margin: 5px 0;
  }
}
</style>
