<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 2L4 5v6.09c0 5.05 3.41 9.76 8 10.91c4.59-1.15 8-5.86 8-10.91V5l-8-3zm-1.06 13.54L7.4 12l1.41-1.41l2.12 2.12l4.24-4.24l1.41 1.41l-5.64 5.66z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IcSharpGppGood",
};
</script>
