<template>
  <section>
    <div class="pros-wrapper">
      <div class="container">
        <div class="pros quality">
          <div class="prose-img">
            <img src="../../assets/img/high-quality-min.png" alt="" />
          </div>
          <div class="prose-content">
            <div class="title">
              {{ quality_title1[lang] }}
              <span>{{ quality_span[lang] }}</span>
              {{ quality_title2[lang] }}
            </div>
            <div class="description">
              {{ quality_description[lang] }}
            </div>
          </div>
        </div>
        <div class="pros light">
          <div class="prose-content">
            <div class="title">
              <span>{{ light_span[lang] }}</span> {{ light_title[lang] }}
            </div>
            <div class="description">
              {{ light_description[lang] }}
            </div>
          </div>
          <div class="prose-img">
            <img src="../../assets/img/girl-hulahoop-min.png" alt="" />
          </div>
        </div>
        <div class="pros safe">
          <div class="prose-img">
            <img src="../../assets/img/boy-rocket-min.png" alt="" />
          </div>
          <div class="prose-content">
            <div class="title">
              {{ safe_title[lang] }}<span>{{ safe_span[lang] }}</span>
            </div>
            <div class="description">
              {{ safe_description[lang] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  props: {
    lang: {
      type: String,
    },
  },
  mounted() {
    this.animate();
  },
  data() {
    return {
      quality_span: {
        en: "correct formation ",
        pl: "prawidłowym uformowaniu ",
      },
      quality_title1: {
        en: "High-quality shoes should not interfere with the ",
        pl: "Wysokiej jakości buty nie powinny przeszkadzać w ",
      },
      quality_title2: {
        en: "of the child's foot",
        pl: "stopy dziecka",
      },
      quality_description: {
        en: "For more than 80 years we have specialized in the production of children's shoes. During this time, we have more than thoroughly studied all the subtleties of children's shoes. We have been involved in scientific research on the production of quality, environmentally, and anatomically correct footwear.",
        pl: "Od ponad 80 lat specjalizujemy się w produkcji obuwia dziecięcego. W tym czasie przestudiowaliśmy więcej niż dokładnie wszystkie subtelności butów dziecięcych. Zaangażowaliśmy się w badania naukowe dotyczące produkcji obuwia wysokiej jakości, ekologicznego i anatomicznie poprawnego.",
      },
      light_span: {
        en: "Lightweight ",
        pl: "Lekka ",
      },
      light_title: {
        en: "and durable outsole - not felt at all on the foot",
        pl: "i wytrzymała podeszwa - w ogóle nie wyczuwalna na stopie",
      },
      light_description: {
        en: "The sole on the slippers is very light and strong enough to protect the child's foot. A reinforced heel gently supports the child's legs during active play. The anatomical insole will take care of the correct formation of the children's legs. Velcro fasteners are easily fastened even by the smallest children.",
        pl: "Podeszwa w kapciach jest bardzo lekka i wystarczająco mocna, aby chronić stopę dziecka. Wzmocniony zapiętek delikatnie podtrzymuje nóżki dziecka podczas aktywnej zabawy. Anatomiczna wkładka zadba o prawidłowe uformowanie nóżek dziecka. Zapięcia na rzepy z łatwością zapinają nawet najmniejsze dzieci.",
      },
      safe_title: {
        en: "Natural materials - ",
        pl: "Naturalne materiały - ",
      },
      safe_span: {
        en: "safe for children",
        pl: "bezpieczny dla dzieci",
      },
      safe_description: {
        en: "In production, we use only natural cotton and safe dyes. Italian glue is very strong, odorless, and absolutely safe. All seams are neatly stitched and do not rub when walking. Safe and natural materials do not obstruct air access and keep feet dry.",
        pl: "W produkcji używamy wyłącznie naturalnej bawełny i bezpiecznych barwników. Włoski klej jest bardzo mocny, bezwonny i całkowicie bezpieczny. Wszystkie szwy są starannie zszyte i nie ocierają się podczas chodzenia. Bezpieczne i naturalne materiały nie utrudniają dostępu powietrza i utrzymują stopy w suchości.",
      },
    };
  },
  methods: {
    animate() {
      gsap.to(".quality .title", {
        scrollTrigger: {
          trigger: ".quality .title",
        },
        duration: 1,
        opacity: 1,
      });
      gsap.to(".quality .title span", {
        scrollTrigger: {
          trigger: ".quality .title span",
          start: "center center",
        },
        duration: 1,
        opacity: 1,
        color: "#75c9e5",
      });
      gsap.to(".quality .description", {
        scrollTrigger: ".quality .description",
        duration: 1,
        opacity: 1,
      });
      gsap.to(".quality .prose-img img", {
        scrollTrigger: ".quality .prose-img img",
        duration: 1,
        opacity: 1,
      });

      gsap.to(".light .title", {
        scrollTrigger: ".light .title",
        duration: 1,
        opacity: 1,
      });
      gsap.to(".light .title span", {
        scrollTrigger: {
          trigger: ".light .title span",
          start: "center center",
        },
        duration: 1,
        opacity: 1,
        color: "#75c9e5",
      });
      gsap.to(".light .description", {
        scrollTrigger: ".light .description",
        duration: 1,
        opacity: 1,
      });
      gsap.to(".light .prose-img img", {
        scrollTrigger: ".light .prose-img img",
        duration: 1,
        opacity: 1,
      });

      gsap.to(".safe .title", {
        scrollTrigger: ".safe .title",
        duration: 1,
        opacity: 1,
      });

      gsap.to(".safe .title span", {
        scrollTrigger: {
          trigger: ".safe .title span",
          start: "center center",
        },
        duration: 1,
        opacity: 1,
        color: "#75c9e5",
      });
      gsap.to(".safe .description", {
        scrollTrigger: ".safe .description",
        duration: 1,
        opacity: 1,
      });
      gsap.to(".safe .prose-img img", {
        scrollTrigger: {
          trigger: ".safe .prose-img img",
          start: "bottom bottom",
        },
        duration: 1,
        x: 0,
        y: 0,
        opacity: 1,
      });
    },
  },
};
</script>

<style scoped>
.pros-wrapper {
  padding: 50px 0;
  background: #fff;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.pros-wrapper::before {
  content: "";
  display: inline-block;
  width: 250px;
  height: 250px;
  background: url("../../assets/img/dots-small-blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 50px;
  left: -150px;
  -webkit-animation: vibrate-3 15s linear infinite both;
  animation: vibrate-3 15s linear infinite both;
}
.pros-wrapper::after {
  content: "";
  display: inline-block;
  width: 150px;
  height: 150px;
  background: url("../../assets/img/dots-red.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  bottom: 400px;
  right: -20px;
  -webkit-animation: vibrate-3 18s linear infinite both;
  animation: vibrate-3 18s linear infinite both;
}
.pros {
  position: relative;
}
.pros.quality,
.pros.light,
.pros.safe {
  display: grid;
  padding: 50px 0;
  position: relative;
}
.pros.light::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url("../../assets/img/line.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: calc(50% - 25px);
  -webkit-animation: vibrate-3 22s linear infinite both;
  animation: vibrate-3 22s linear infinite both;
}
.pros.safe::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url("../../assets/img/stars.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: calc(50% - 25px);
  -webkit-animation: vibrate-3 25s linear infinite both;
  animation: vibrate-3 25s linear infinite both;
}
.pros.quality,
.pros.safe {
  grid-template-columns: 1fr 2fr;
}
.pros.light {
  grid-template-columns: 2fr 1fr;
}
.prose-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.prose-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pros .description {
  margin-top: 50px;
}
.quality .title,
.light .title,
.safe .title {
  opacity: 0;
}
.quality .description,
.light .description,
.safe .description {
  opacity: 0;
}
.quality .prose-img img,
.light .prose-img img,
.safe .prose-img img {
  opacity: 0;
}

.safe .prose-img img {
  transform: translate(-200px, 100px);
  /* -webkit-animation: vibrate-3 6s linear infinite both;
  animation: vibrate-3 6s linear infinite both; */
}

@media (max-width: 991px) {
  .prose-img img {
    width: 200px;
  }
}
@media (max-width: 768px) {
  .pros {
    margin: 50px 0;
  }
  .prose-img img {
    width: 100px;
  }
  .prose-img {
    position: absolute;
  }
  .pros.quality .prose-img {
    bottom: -20px;
    right: 0px;
  }
  .pros.light .prose-img {
    bottom: -20px;
    right: 0px;
  }
  .pros.safe .prose-img {
    bottom: -20px;
    right: 0px;
    transform: rotateY(180deg);
  }
  .pros.quality,
  .pros.light,
  .pros.safe {
    display: block;
  }
  .pros-wrapper::before {
    left: -100px;
    top: 0;
    width: 150px;
    height: 150px;
  }
  .pros-wrapper::after {
    width: 100px;
    height: 100px;
    left: 100px;
    bottom: -50px;
  }
}
.vibrate-3 {
  -webkit-animation: vibrate-3 25s linear infinite both;
  animation: vibrate-3 25s linear infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-7-22 15:46:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-3
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
</style>
