<template>
  <div class="lang-wrapper">
    <select v-model="lang" name="lang" id="">
      <option value="en">en</option>
      <!-- <option value="pl">pl</option> -->
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: "en",
    };
  },
  mounted() {
    fetch("lang.json")
      .then((response) => response.json())
      .then((json) => (this.lang = json.lang));
  },
  watch: {
    lang() {
      this.changeLang();
    },
  },
  methods: {
    changeLang() {
      this.$store.commit("changeLocation", this.lang);
    },
  },
};
</script>

<style scoped>
select {
  padding: 5px 10px;
  height: 30px;
  border: 1px solid var(--accent);
  border-radius: 5px;
  background: none;
  color: var(--header);
  font-weight: 700;
}
</style>
