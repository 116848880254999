<template>
  <section>
    <div class="hero-wrapper">
      <div class="container">
        <div class="hero">
          <div class="hero-content">
            <h1>{{ h1[lang] }}</h1>
            <p>
              {{ description[lang] }}
            </p>
            <div class="btn">
              <router-link to="/catalog">
                <button>{{ btn[lang] }}</button>
              </router-link>
            </div>
          </div>
          <div class="hero-img">
            <img src="../../assets/img/boy-min.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  props: {
    lang: {
      type: String,
    },
  },
  data() {
    return {
      h1: {
        en: "Home slippers for children",
        pl: "Domowe kapcie dla dzieci",
      },
      description: {
        en: "Slippers for children from 1 year. Ideal for the child's first steps, games in the house, and for classes in kindergarten. ",
        pl: "Kapcie dla dzieci od 1 roku. Idealny na stawianie pierwszych kroków dziecka, zabawy w domu, a także na zajęcia w przedszkolu.",
      },
      btn: {
        en: "View Catalog",
        pl: "Zobacz Katalog",
      },
    };
  },
  mounted() {
    this.animate();
  },
  methods: {
    animate() {
      gsap.to(".hero-content h1", {
        scrollTrigger: ".hero-content h1",
        duration: 1,
        opacity: 1,
      });
      gsap.to(".hero-content p", {
        scrollTrigger: ".hero-content p",
        delay: 0.2,
        duration: 1,
        opacity: 1,
      });
      gsap.to(".btn button", {
        scrollTrigger: ".btn button",
        delay: 0.4,
        duration: 1,
        opacity: 1,
      });
      gsap.to(".hero-img", {
        scrollTrigger: ".hero-img",
        duration: 1,
        opacity: 1,
      });
    },
  },
};
</script>

<style scoped>
.hero {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 100%;
  min-height: 600px;
  position: relative;
}
.hero-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hero-img {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.hero h1 {
  font-size: 64px;
  font-weight: 700;
  opacity: 0;
}
.hero p {
  padding: 25px 0;
  line-height: 32px;
  opacity: 0;
}
.btn button {
  width: 300px;
  height: 50px;
  background: var(--active);
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
}
.btn button a {
  text-decoration-line: none;
}
.btn button:hover {
  background: #bd1d37;
}
@media (max-width: 991px) {
  .hero {
    grid-template-columns: 3fr 1fr;
  }
  .hero img {
    width: 300px;
  }
}
@media (max-width: 768px) {
  .hero {
    display: block;
  }
  .hero-content {
    align-items: center;
    text-align: center;
    min-height: 650px;
    width: 100%;
  }
  .hero img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150px;
    z-index: -1;
  }
}
@media (max-width: 576px) {
  .hero h1 {
    font-size: 48px;
  }
  .btn button {
    width: 100%;
    padding: 0 40px;
  }
}
</style>
