<template>
  <div>
    <header :class="{ white: scroll }">
      <div class="container">
        <nav>
          <div class="logo">
            <router-link to="/">Beregynya</router-link>
          </div>
          <div :class="{ active: isOpen }" class="navs">
            <router-link @click="link" to="/">{{
              home_link[lang]
            }}</router-link>
            <router-link @click="link" to="/catalog">{{
              catalog_link[lang]
            }}</router-link>
            <router-link @click="link" to="/contact">{{
              contact_link[lang]
            }}</router-link>
          </div>
          <app-lang></app-lang>
          <header-ham
            :isActive="isOpen"
            id="ham"
            @toggleMenu="toggleMenu"
          ></header-ham>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderHam from "@/components/HeaderHam.vue";
import AppLang from "@/components/AppLang.vue";
export default {
  components: {
    HeaderHam,
    AppLang,
  },
  props: {
    lang: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
      scroll: false,
      home_link: {
        en: "Home",
        pl: "Dom",
      },
      catalog_link: {
        en: "Catalog",
        pl: "Katalog",
      },
      contact_link: {
        en: "Contact",
        pl: "Kontakt",
      },
    };
  },
  computed: {
    ...mapGetters(["getLocation"]),
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  unmounted() {
    window.removeEventListener("scroll");
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    link() {
      this.toggleMenu();
    },
    scrollPage() {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 50) {
        this.scroll = true;
      } else this.scroll = false;
    },
  },
};
</script>

<style scoped>
header {
  width: 100%;
  padding: 10px 0;
  position: fixed;
  z-index: 10;
}
.white {
  background: #fff;
  box-shadow: 1px 1px 1px 0 #f7f7f7;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav a {
  text-decoration-line: none;
  color: var(--header);
  transition: all 0.3s ease;
  font-weight: 700;
  cursor: pointer;
}
nav a:hover {
  color: var(--accent);
}
.logo {
  font-size: 24px;
}
.logo .router-link-active {
  color: var(--header);
}
.navs {
  display: flex;
  align-items: center;
}
.navs a {
  padding: 0 20px;
}
.navs a:last-child {
  padding: 0 0 0 20px;
}
.router-link-active {
  color: var(--accent);
}
#ham {
  display: none;
}
@media (max-width: 768px) {
  #ham {
    display: block;
  }
  .navs {
    transform: translateX(-100%);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: #fff;
    transition: all 0.3s ease;
  }
  .navs.active {
    transform: translateX(0);
  }
  .navs a {
    padding: 20px 0;
  }
  .navs a:last-child {
    padding: 20px 0;
  }
}
</style>
