<template>
  <div class="home">
    <home-hero :lang="getLocation"></home-hero>
    <home-skills :lang="getLocation"></home-skills>
    <home-pros :lang="getLocation"></home-pros>
    <home-low-price :lang="getLocation"></home-low-price>
    <home-offer :lang="getLocation"></home-offer>
    <app-footer :lang="getLocation"></app-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeHero from "@/components/home/HomeHero.vue";
import HomeSkills from "@/components/home/HomeSkills.vue";
import HomePros from "@/components/home/HomePros.vue";
import HomeLowPrice from "@/components/home/HomeLowPrice.vue";
import HomeOffer from "@/components/home/HomeOffer.vue";
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: "HomeView",
  components: {
    HomeHero,
    HomeSkills,
    HomePros,
    HomeLowPrice,
    HomeOffer,
    AppFooter,
  },
  computed: {
    ...mapGetters(["getLocation"]),
  },
};
</script>
