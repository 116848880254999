import { createStore } from "vuex";

export default createStore({
  state: {
    lang: "",
  },
  getters: {
    getLocation: (state) => state.lang,
  },
  mutations: {
    changeLocation(state, payload) {
      state.lang = payload;
    },
    addLocation(state, payload) {
      state.lang = payload.lang;
    },
  },
  actions: {
    getData(context) {
      fetch("lang.json")
        .then((response) => response.json())
        .then((json) => context.commit("addLocation", json));
    },
  },
  modules: {},
});
