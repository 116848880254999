<template>
  <app-header :lang="getLocation"></app-header>
  <router-view />
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    AppHeader,
  },

  mounted() {
    this.getData();
  },
  computed: {
    ...mapGetters(["getLocation"]),
  },
  methods: {
    ...mapActions(["getData"]),
  },
};
</script>

<style>
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito-Regular.ttf");
  font-weight: 400;
}
#app {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--header);
}
body {
  background: var(--bg);
  overflow-x: hidden;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --bg: #e7e7f8;
  --header: #26262b;
  --text: #595757;
  --active: #fe1d41;
  --accent: #75c9e5;
  --swiper-pagination-color: #75c9e5;
  --swiper-navigation-color: #75c9e5;
  --swiper-navigation-size: 24px !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.title span,
.description span {
  position: relative;
}

.description span::before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 3px;
  left: 0;
  background: var(--accent);
  border-radius: 10px;
}
.description span::before {
  bottom: 0;
}
.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.05em;
  text-align: start;
}
.description {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: var(--text);
}
</style>
