<template>
  <section>
    <div class="low-prise-wrapper">
      <div class="container">
        <div class="low-price">
          <div class="low-price-content">
            <div class="title">
              {{ price_title[lang] }}
            </div>
            <div class="description">
              {{ price_description[lang] }}
              <span>{{ price_description_span[lang] }}</span>
            </div>
            <div class="link">
              <router-link to="/contact"
                >{{ price_btn[lang] }}
                <ic-round-keyboard-arrow-right></ic-round-keyboard-arrow-right
              ></router-link>
            </div>
          </div>
          <div class="low-price-img">
            <img src="../../assets/img/low-price-boy-min.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import IcRoundKeyboardArrowRight from "@/components/svg/IcRoundKeyboardArrowRight.vue";
export default {
  components: {
    IcRoundKeyboardArrowRight,
  },
  props: {
    lang: {
      type: String,
    },
  },
  data() {
    return {
      price_title: {
        en: "House slippers for children. ",
        pl: "Domowe kapcie dla dzieci. ",
      },
      price_span: {
        en: "Only 4 euro",
        pl: "Tylko 4 euro",
      },
      price_description: {
        en: "A child's foot is constantly growing, which requires constant updating of the wardrobe. And buying new shoes is a financial waste. We tried to reduce the waste of parents and made high-quality slippers at the lowest possible price. They are perfect for ",
        pl: "Stopa dziecka stale rośnie, co wymaga ciągłej aktualizacji garderoby. A kupowanie nowych butów to strata finansowa. Staraliśmy się ograniczyć marnotrawstwo rodziców i wykonaliśmy wysokiej jakości kapcie w możliwie najniższej cenie. Idealne do ",
      },
      price_description_span: {
        en: "kindergarten.",
        pl: "przedszkola.",
      },
      price_btn: {
        en: "Contact us",
        pl: "Skontaktuj się z nami",
      },
    };
  },
  mounted() {
    this.animate();
  },
  methods: {
    animate() {
      gsap.to(".low-price .title", {
        scrollTrigger: ".low-price .title",
        duration: 1,
        opacity: 1,
      });
      gsap.to(".low-price .description", {
        scrollTrigger: ".low-price .title",
        duration: 1,
        opacity: 1,
      });
      gsap.to(".low-price .link a", {
        scrollTrigger: ".low-price .link a",
        duration: 1,
        opacity: 1,
      });
    },
  },
};
</script>

<style scoped>
.low-prise-wrapper {
  padding: 150px 0;
}
.low-price {
  width: 100%;
  background: #fff;
  padding: 50px;
  border-radius: 15px;
  position: relative;
}
.low-price .title {
  opacity: 0;
}
.low-price .description {
  opacity: 0;
}
.low-price-content {
  width: 70%;
}
.low-price-content .description {
  margin-top: 25px;
}
.low-price-img {
  position: absolute;
  right: 0;
  bottom: 0px;
  transform: scale(0.8);
}
.link {
  margin-top: 25px;
}
.link a {
  text-decoration-line: none;
  color: var(--accent);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  opacity: 0;
  display: flex;
  align-items: center;
}
.link a svg {
  fill: var(--accent);
  transition: all 0.3s ease;
}
.link a:hover svg {
  transform: translateX(3px);
}
@media (max-width: 991px) {
  .low-price-img {
    bottom: 50px;
    right: 0;
  }
}
@media (max-width: 768px) {
  .low-price-content {
    width: 100%;
  }
  .low-price-img {
    top: -100px;
    right: 0;
  }
  .low-price-img img {
    width: 150px;
  }
}
@media (max-width: 500px) {
  .low-price-content,
  .low-price-content .title {
    text-align: center;
  }
  .low-price-img {
    top: -165px;
    right: 0;
  }
  .low-price-img img {
    width: 150px;
  }
}
</style>
