<template>
  <section>
    <div class="skills-wrapper">
      <div class="container">
        <div class="skills">
          <div class="skill">
            <ic-outline-eco id="skill-1"></ic-outline-eco>
            <div class="title">{{ eco_title[lang] }}</div>
            <div class="description">
              {{ eco_description[lang] }}
            </div>
          </div>
          <div class="skill">
            <ic-outline-health-and-safety
              id="skill-2"
            ></ic-outline-health-and-safety>
            <div class="title">{{ safety_title[lang] }}</div>
            <div class="description">
              {{ safety_description[lang] }}
            </div>
          </div>
          <div class="skill">
            <ic-outline-cloud id="skill-3"></ic-outline-cloud>
            <div class="title">{{ lightweight_title[lang] }}</div>
            <div class="description">
              {{ lightweight_description[lang] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import IcOutlineEco from "@/components/svg/IcOutlineEco.vue";
import IcOutlineHealthAndSafety from "@/components/svg/IcOutlineHealthAndSafety.vue";
import IcOutlineCloud from "@/components/svg/IcOutlineCloud.vue";
export default {
  components: {
    IcOutlineEco,
    IcOutlineHealthAndSafety,
    IcOutlineCloud,
  },
  props: {
    lang: {
      type: String,
    },
  },
  data() {
    return {
      eco_title: {
        en: "ecology and nature",
        pl: "ekologia i natura",
      },
      eco_description: {
        en: "Footwear made from natural materials, which is confirmed by relevant certificates.",
        pl: "Obuwie wykonane z naturalnych materiałów, co potwierdzają odpowiednie certyfikaty.",
      },
      safety_title: {
        en: "child safety",
        pl: "bezpieczeństwo dzieci",
      },
      safety_description: {
        en: "The correct design does not interfere with the proper growth of the child's foot.",
        pl: "Prawidłowy projekt nie zakłóca prawidłowego wzrostu stopy dziecka.",
      },
      lightweight_title: {
        en: "lightweight and comfortable",
        pl: "lekki i wygodny",
      },
      lightweight_description: {
        en: "Slippers are very light and have an excellent fixation. The outsole is soft and durable, and cushions on hard ground.",
        pl: "Kapcie są bardzo lekkie i mają doskonałe mocowanie. Podeszwa jest miękka i trwała, a amortyzuje na twardym podłożu.",
      },
    };
  },
  mounted() {
    this.animate();
  },
  methods: {
    animate() {
      gsap.to("#skill-1", {
        scrollTrigger: {
          trigger: "#skill-1",
          start: "center center",
        },
        duration: 1,
        fill: "#75c9e5",
      });
      gsap.to("#skill-2", {
        scrollTrigger: {
          trigger: "#skill-2",
          start: "center center",
        },
        duration: 1,
        fill: "#75c9e5",
      });
      gsap.to("#skill-3", {
        scrollTrigger: {
          trigger: "#skill-3",
          start: "center center",
        },
        duration: 1,
        fill: "#75c9e5",
      });
    },
  },
};
</script>

<style scoped>
.skills-wrapper {
  padding: 0 0 150px 0;
}

.skills {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
.skills::before {
  content: "";
  display: inline-block;
  width: 100px;
  height: 100px;
  background: url("../../assets/img/dots-red.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: -20px;
  top: -50px;
  z-index: -1;
  -webkit-animation: rotate-center 60s ease-in-out infinite both;
  animation: rotate-center 60s ease-in-out infinite both;
}
.skills::after {
  content: "";
  display: inline-block;
  width: 200px;
  height: 200px;
  background: url("../../assets/img/dots-blue.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: -75px;
  bottom: -70px;
  z-index: -1;
  -webkit-animation: rotate-center 90s ease-in-out infinite both;
  animation: rotate-center 90s ease-in-out infinite both;
}
.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.skill svg {
  width: 132px;
  height: 132px;
  fill: var(--bg);
}
.skill .title {
  text-transform: capitalize;
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  text-align: center;
}
.skill .description {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: var(--text);
  text-align: center;
  padding: 10px 0;
}

@media (max-width: 991px) {
  .skill .title {
    font-size: 18px;
    line-height: 24px;
  }
  .skill .description {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (max-width: 768px) {
  .skills {
    display: flex;
    flex-direction: column;
  }
  .skill {
    margin: 30px 0;
  }
  .skills::after {
    right: 0px;
    bottom: -70px;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-22 15:50:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
